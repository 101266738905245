import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

import { Button, Card, Text, useThemeSafe } from '@frontend-monorepo/cyolo-ui';

import XSign from '../../../../assets/exclamation-mark.svg';
import VSign from '../../../../assets/v-sign-circle.svg';
import pageRedirect from '../../../services/page-redirect';

const logoutURL = '/cyolo/v1/logout';
export type variant = 'success' | 'error';

const headers = new Map<variant, string>([
  ['success', `You're All Set!`],
  ['error', 'Something went wrong'],
]);

const descriptions = new Map<variant, string>([
  [
    'success',
    'Your password has been reset. Please use your new password to login again.',
  ],
  ['error', 'Please contact your administrator for further assistance.'],
]);

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 450,
    padding: 40,
  },
  header: {
    display: 'flex',
    marginTop: 20,
  },
  description: {
    display: 'flex',
    textAlign: 'center',
    width: 242,
    marginTop: 10,
    marginBottom: 30,
  },
});

interface FinishStepProps {
  type?: variant;
  redirectPath?: string;
}

const FinishStep: FC<FinishStepProps> = ({
  type = 'success',
  redirectPath = '/login',
}) => {
  const history = useHistory();
  const theme = useThemeSafe();
  const styles = useStyles({ theme });
  const image = type === 'success' ? VSign : XSign;
  return (
    <Card className={styles.root}>
      <img src={image} alt="icon" />
      <div className={styles.header}>
        <Text variant="login-sub-title">{headers.get(type)}</Text>
      </div>
      <div className={styles.description}>
        <Text variant="body">{descriptions.get(type)}</Text>
      </div>
      <Button
        variant="primary"
        onClick={() => {
          if (redirectPath === logoutURL) {
            pageRedirect.performPageRedirect(logoutURL);
            return;
          }

          history.push(redirectPath);
        }}
      >
        Return to Login
      </Button>
    </Card>
  );
};

export default FinishStep;
