import { makeAutoObservable, reaction } from 'mobx';

import { TextVariantType } from '@frontend-monorepo/cyolo-ui-core';

import { AppRoute } from '../../routes';
import API from '../../services/api/api';
import { ValidationError } from '../../services/api/response';
import DataStoreContainer from '../data/data';
import LocationStore from '../location-store';

export enum HoldupType {
  Disabled = 'disabled',
  Supervisor = 'supervisor',
  Authorization = 'unauthorized',
  Unknown = 'unknown',
  ApprovalWaiting = 'approvalwaiting',
}

const SECOND_IN_MILLIS = 1000;

class WaitingScreenState {
  public validationError: ValidationError | null = null;
  public loaded = false;
  public _expirationTime;
  currentTime = new Date();

  constructor(
    private readonly dataStore: DataStoreContainer,
    private readonly locationStore: LocationStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.dataStore.approvalStore.expirationTime,
      (expirationTime) => {
        this.setExpirationTime(expirationTime);
      },
    );

    setInterval(() => {
      this.setCurrentTime();
    }, 1000);
  }

  /**
   * bootstraps the store initial data
   */
  async bootstrap(onError?: (error: unknown) => void) {
    await this.dataStore.urlsStore.fetch();
    await this.fetchValidationError(onError);
    this.loaded = true;
  }

  async fetchValidationError(
    onError?: (error: unknown) => void,
  ): Promise<void> {
    try {
      this.validationError = await API.validationError();
    } catch (error) {
      onError?.(error);
    }
  }

  async commitApprovalRequestSeen() {
    return this.dataStore.approvalStore.commitPrependingApproval();
  }

  get urlStoreGotError(): boolean {
    return this.dataStore.urlsStore.state === 'error';
  }

  setExpirationTime(expiration: string) {
    this._expirationTime = expiration;
  }

  setCurrentTime() {
    this.currentTime = new Date();
  }

  get expirationTimeSeconds(): number | undefined {
    if (!Boolean(this._expirationTime) || this._expirationTime === '') {
      return;
    }

    const futureDate = new Date(this._expirationTime);
    const diffMillis = futureDate.getTime() - this.currentTime.getTime();
    const diffSeconds = Math.floor(diffMillis / SECOND_IN_MILLIS);

    return diffSeconds;
  }

  /**
   * returns a link to the applications portal
   * @returns
   */
  get applicationsPortalLink(): string {
    return this.dataStore.urlsStore.data?.applicationsPortal || '';
  }

  get holdupType(): HoldupType {
    switch (this.locationStore.currentLocation) {
      case AppRoute.Supervisor:
        return HoldupType.Supervisor;
      case AppRoute.Disabled:
        return HoldupType.Disabled;
      case AppRoute.NoPermission:
        return HoldupType.Authorization;
      case AppRoute.ApprovalWaiting:
        return HoldupType.ApprovalWaiting;
      default:
        return HoldupType.Unknown;
    }
  }

  // returns true if "read more" prop has data
  validationErrorExists = (): boolean => {
    return Boolean(this.validationError);
  };

  get remediationExist(): boolean {
    return Boolean(this.validationError.remediation_link);
  }

  // we show detailed error only if "read more" prop exists
  get showDetailedError(): boolean {
    return this.validationErrorExists();
  }

  get headerVariant(): TextVariantType {
    if (this.validationErrorExists()) {
      return 'header-2';
    }

    return 'page-header';
  }

  get titleText(): string {
    // we show detailed error only if "read more" prop exists
    if (this.validationErrorExists()) {
      let message = this.validationError.failure_reason;
      if (this.remediationExist) {
        message = `${message}.\nTo understand and resolve this issue click Read more below. Once resolved, click Retry.`;
      }

      return message;
    }

    switch (this.holdupType) {
      case HoldupType.Disabled:
        return 'Your account is disabled and we cannot log you in';
      case HoldupType.Supervisor:
        return 'Please Wait for Access Approval';
      case HoldupType.Authorization:
        return "You don't have permission to view this app currently";
      case HoldupType.ApprovalWaiting:
        return 'Please Wait for Access Approval';
      default:
        return '';
    }
  }

  get bodyText(): string {
    // we show detailed error only if "read more" prop exists
    if (this.validationErrorExists()) {
      return ``;
    }

    switch (this.holdupType) {
      case HoldupType.Disabled:
        return 'We recommend that you contact your admin for further assistance';
      case HoldupType.Supervisor:
        return 'If it’s taking too long, we recommend that you contact your supervisor';
      case HoldupType.Authorization:
        return 'If you are supposed to connect to this app we recommend that you contact your admin';
      case HoldupType.ApprovalWaiting:
        if (!this.expirationTimeSeconds) {
          return '';
        }
        return 'The request will expire in ';
      default:
        return '';
    }
  }
}

export default WaitingScreenState;
