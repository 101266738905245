import { useEffect, useState } from 'react';

export const useKeyboardPress = (targetCode: number): boolean => {
  const [keyPressed, setKeyPressed] = useState(false);
  const [inTimeout, setInTimeout] = useState(false);
  const LONG_PRESS_INTERVAL_MS = 150;

  /**
   * on keydown press
   */
  function handlerDownKey(e) {
    if (e.keyCode === targetCode) {
      // support long press
      if (keyPressed && !inTimeout) {
        setInTimeout(true);
        setTimeout(() => {
          setInTimeout(false);
          setKeyPressed(false);
        }, LONG_PRESS_INTERVAL_MS);
      }

      setKeyPressed(true);
    }
  }

  /**
   * on keyup press
   */
  function handlerUpKey(e) {
    if (e.keyCode === targetCode) {
      setKeyPressed(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handlerDownKey);
    window.addEventListener('keyup', handlerUpKey);

    return () => {
      window.removeEventListener('keydown', handlerDownKey);
      window.addEventListener('keyup', handlerUpKey);
    };
  });

  return keyPressed;
};
