import ms from 'ms';

import { action, computed, makeObservable, observable } from 'mobx';

class RequestIntervalStore {
  reqTimeDelta: number;

  constructor() {
    makeObservable(this, {
      reqTimeDelta: observable,
      updateTimeDelta: action.bound,
      isTimeoutOver: computed,
      formattedtimeUntilSmsCanBeSentAgain: computed,
    });

    this.bootstrap();
  }

  bootstrap() {
    this.updateTimeDelta(0);
    this.updateInterval();
  }

  updateInterval() {
    const currentUtcTime = Date.now();
    const nextSecondUtc = Math.ceil(currentUtcTime / 1000) * 1000;
    const timeToNextSecond = nextSecondUtc - currentUtcTime;

    setTimeout(() => {
      this.updateTimeDelta(this.reqTimeDelta - 1000);
      this.updateInterval();
    }, timeToNextSecond);
  }

  get isTimeoutOver(): boolean {
    return this.reqTimeDelta <= 0;
  }

  updateTimeDelta(val: number) {
    this.reqTimeDelta = val;
  }

  get formattedtimeUntilSmsCanBeSentAgain(): string {
    if (this.reqTimeDelta < ms('1s')) {
      return;
    }

    const minutes = Math.floor(this.reqTimeDelta / 60000);
    const seconds = ((this.reqTimeDelta % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, '0')}`;
  }
}

export default RequestIntervalStore;
