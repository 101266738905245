/* eslint-disable simple-import-sort/imports */
/* Libs */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'react-jss';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { Provider } from 'mobx-react';

import { ToastProvider } from '@frontend-monorepo/cyolo-toast';
import { cyTheme } from '@frontend-monorepo/cyolo-ui';
import { Stage, StageUtils as stageUtil } from '@frontend-monorepo/cyolo-utils';

/* Components */
import App from './components/App';
/* Stores */
import RootStore from './stores/root-store';
/* Config */
import config from './config.json';

/* Styles */
import '../../../shared/css/fonts.css';
import '@frontend-monorepo/cyolo-ui/lib/main.css';
import './index.scss';
import '@fontsource/heebo/100.css';
import '@fontsource/heebo/200.css';
import '@fontsource/heebo/300.css';
import '@fontsource/heebo/400.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import '@fontsource/heebo/800.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';

//@ts-expect-error webpack build time global variable
console.log(`Running App version ${config.appVersion} (${COMMIT_HASH})`);

/* MobX */
const rootStore = new RootStore();

let root = (
  <Provider rootStore={rootStore}>
    <ThemeProvider theme={cyTheme}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </ThemeProvider>
  </Provider>
);

interface Window {
  bugsnagEnabled?: boolean;
}

const { bugsnagEnabled = true } = window as Window;

if (bugsnagEnabled) {
  /* Bugsnag */
  Bugsnag.start({
    apiKey: config.bugSnag.apiKey,
    plugins: [new BugsnagPluginReact()],
    appVersion: config.appVersion, // static app version
    releaseStage: stageUtil.inferAppStage(), // try to get the current stage
    enabledReleaseStages: [Stage.Prod, Stage.Staging], // only send events in prod and staging
    onError: (event) => {
      //TODO: needs to be refactored
      // add company name to report
      if (rootStore.dataStores.companyInfoStore.state !== 'done') return;
      const { data } = rootStore.dataStores.companyInfoStore;
      event.addMetadata('company', {
        name: data.name,
      });
    },
  });

  // due to an invalid type error React cannot be passed as an arg to bugsnag

  // @ts-ignore
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  root = <ErrorBoundary>{root}</ErrorBoundary>;
}

ReactDOM.render(root, document.getElementById('root'));
