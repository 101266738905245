import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import { CyTheme } from '@frontend-monorepo/cyolo-ui';

const useStyles = createUseStyles<
  'root' | 'digit',
  {
    digitFontSize?: CSSProperties['fontSize'];
  }
>((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  digit: ({ digitFontSize }) => ({
    textAlign: 'center',
    width: 8,
    flex: 'auto',
    marginLeft: 2,
    marginRight: 2,
    border: 'none',
    WebkitBorderRadius: 0,
    outline: 'none',
    fontSize: digitFontSize ? digitFontSize : '1.2rem',
    caretColor: 'transparent',
    fontFamily: theme.typography.p.fontFamily,
    backgroundColor: 'transparent',
    borderBottom: `solid 2px ${theme.palette.grey.extraLight}`,
    color: theme.palette.secondary.color,

    ['&:focus']: {
      borderBottom: `solid 1.5px ${theme.palette.primary.color}`,
    },
  }),
}));

export default useStyles;
