import { makeAutoObservable } from 'mobx';

import { DataState } from '@frontend-monorepo/cyolo-store';

import API from '../services/api/api';
import { SupervisorApprovalTokenResponse } from '../services/api/response';
import pageRedirect from '../services/page-redirect';

type SupervisorApprovalInfo = SupervisorApprovalTokenResponse;

class SupervisorApprovalStore {
  state: DataState;
  supervisorApprovalInfo: SupervisorApprovalInfo | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchSupervisorApprovalToken(): Promise<SupervisorApprovalInfo> {
    // parse out code
    const code = new URLSearchParams(location.search).get('code');

    // verify that approval token is not null/undefined
    if (!Boolean(code)) {
      return;
    }

    try {
      const supervisorApprovalInfo = await API.fetchSupervisorApprovalToken(
        code,
      );

      this.state = 'done';
      this.supervisorApprovalInfo = supervisorApprovalInfo;

      return supervisorApprovalInfo;
    } catch (error) {
      this.state = 'error';

      pageRedirect.performPageRedirectUnauthorized();
    }
  }
}

export default SupervisorApprovalStore;
