import React, { FC, ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

import { when } from 'mobx';
import { observer } from 'mobx-react';

import { Card } from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../../hooks';
import { AppRoute } from '../../../../routes';
import pageRedirect from '../../../../services/page-redirect';
import { AppLoadingIndicator } from '../../../shared';
import PasswordForm from '../PasswordForm';

const PasswordScreen: FC = () => {
  const { stateStore, dataStores } = useRootStore();
  const { passwordScreenState } = stateStore;

  const history = useHistory();
  const styles = useStyle();

  useEffect(() => {
    const checkIsPasswordProviderDispose = when(
      () => !Boolean(dataStores.userIdentityStore.data.passwordProvider),
      () => {
        history.replace(AppRoute.Login);
      },
    );

    const checkIsAuthenticatedUserDispose = when(
      () => !dataStores.authDataStore.data.isAnonymous,
      () => {
        pageRedirect.performPageRedirect();
      },
    );

    return () => {
      checkIsPasswordProviderDispose();
      checkIsAuthenticatedUserDispose();
    };
  }, []);

  const renderCardContent = (): ReactElement => {
    switch (passwordScreenState.passwordState) {
      case 'in-work':
        return <AppLoadingIndicator />;
      case 'idle':
        return <PasswordForm />;
      default:
        return null;
    }
  };
  return (
    <div className={styles.passwordViewRoot}>
      <div className={styles.cardContainer}>
        <Card className={styles.card}>
          <div className={styles.cardContent}>{renderCardContent()}</div>
        </Card>
      </div>
    </div>
  );
};

export default observer(PasswordScreen);

const useStyle = createUseStyles({
  passwordViewRoot: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardContainer: {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: 440,
    padding: '80px 0 !important',
  },
});
