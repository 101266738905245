import {
  PasswordPolicy,
  PasswordValidator,
  PolicyRulesValidator,
} from '../../../model';
import InvitationUserAPI from '../../../services/api/inviteUser';
import passwordApi from '../../../services/password-api';

import NewPasswordState from './new-password-state';
import ResetPasswordScreenBase from './reset-password-screen-base';

export type Step = 'done' | 'current' | 'future';

const totalNumberOfSteps = 3;
class ResetInvitedUserPasswordScreenState extends ResetPasswordScreenBase {
  passwordPolicy: PasswordPolicy;
  currentStep: number;

  constructor() {
    super();
    this.reset();
  }

  get isComplexityValid(): boolean {
    return this.validator?.doesMeetAll();
  }

  get doPasswordsMatch(): boolean {
    return this.validator?.doPasswordsMatch(
      this.newPasswordState.newPassword,
      this.newPasswordState.confirmPassword,
    );
  }

  get isValid(): boolean {
    let areRulesPassed =
      this.isComplexityValid &&
      this.doPasswordsMatch &&
      this.policyValidator.minLengthRule.isValid &&
      this.policyValidator.maxLengthRule.isValid;

    if (this.enableBlackList) {
      areRulesPassed =
        areRulesPassed && this.policyValidator.blacklistRule.isValid;
    }

    if (this.policyValidator?.state.isPending) {
      return false;
    }

    return areRulesPassed;
  }

  setPasswordPolicy(passwordPolicy: PasswordPolicy) {
    this.passwordPolicy = passwordPolicy;

    const { enableBlacklist, maxLength, minComplexity, minLength } =
      passwordPolicy;

    this.setupValidator({
      minComplexity,
      minLength,
      maxLength,
      enableBlacklist,
    });
  }

  async setupValidator(policyOptions?: {
    minComplexity: number;
    minLength: number;
    maxLength: number;
    enableBlacklist: boolean;
  }): Promise<void> {
    const rules = await passwordApi.getPasswordRules();
    this.validator = new PasswordValidator(rules, policyOptions?.minComplexity);
    if (policyOptions) {
      const { enableBlacklist, maxLength, minLength } = policyOptions;

      this.enableBlackList = enableBlacklist;
      this.policyValidator = new PolicyRulesValidator(minLength, maxLength);
    }
  }

  async resetPassword(): Promise<void> {
    if (!this.isValid) {
      return;
    }

    await passwordApi.performResetPasswordForInvitedUser(
      this.newPasswordState.newPassword,
    );
  }

  resetState() {
    this.newPasswordState = new NewPasswordState();
  }

  stepState(step: number): Step {
    if (step < this.currentStep) {
      return 'done';
    }

    if (step === this.currentStep) {
      return 'current';
    }

    return 'future';
  }

  nextStep() {
    this.currentStep = Math.min(this.currentStep + 1, totalNumberOfSteps);
  }

  reset() {
    this.currentStep = 1;
  }

  async sendInvitationToken(token: string) {
    return InvitationUserAPI.sendInviteToken(token);
  }
}

export default ResetInvitedUserPasswordScreenState;
