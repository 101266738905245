import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react';

import {
  CyTheme,
  LoadingOverlay,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../hooks/useRootStore';
import Endpoints from '../../../services/api/endpoints';
import PasswordResetAPI from '../../../services/api/resetPassword';
import pageRedirect from '../../../services/page-redirect';
import { ValidateType } from '../../../services/password-api';
import { useResetScreenReaction } from '../../hooks';
import ResetPasswordWrapper from '../../shared/ResetPasswordWrapper';

import Sections from './Sections';

interface InvitationTokenParams {
  token?: string;
}

const CreateNewPassword: FC = () => {
  const {
    stateStore: { invitedUserScreenState, userStore },
    uiStore,
  } = useRootStore();

  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  useResetScreenReaction(
    invitedUserScreenState,
    uiStore,
    ValidateType.UpdatePassword,
  );

  const { token } = useParams<InvitationTokenParams>();

  useEffect(() => {
    const checkInvitedUserToken = async () => {
      try {
        uiStore.setLoadingState('pending');
        await invitedUserScreenState.sendInvitationToken(token);

        await userStore.fetch();
        const authProvider = await PasswordResetAPI.userIdentityProvider(
          userStore.data.name,
        );
        invitedUserScreenState.setPasswordPolicy(authProvider.passwordPolicy);
        uiStore.setLoadingState('done');
      } catch (error) {
        uiStore.setLoadingState('error');
      }
    };

    if (token) {
      checkInvitedUserToken();
    }
  }, [token]);

  if (invitedUserScreenState.validator === null) {
    return <LoadingOverlay />;
  }

  return (
    <ResetPasswordWrapper>
      <ResetPasswordWrapper.Title>
        <Text variant="login-sub-title">Set a password</Text>
        <Text variant="body">Create a strong password</Text>
      </ResetPasswordWrapper.Title>
      <ResetPasswordWrapper.Body>
        <Sections store={invitedUserScreenState} />
        <div className={styles.cancelWrap}>
          <Text
            variant="body"
            color={theme.palette.primary.color}
            onClick={() => pageRedirect.performPageRedirect(Endpoints.logout)}
          >
            Cancel
          </Text>
        </div>
      </ResetPasswordWrapper.Body>
    </ResetPasswordWrapper>
  );
};

export default observer(CreateNewPassword);

const useStyles = createUseStyles((theme: CyTheme) => ({
  cancelWrap: {
    display: 'flex',
    marginTop: 32,
    marginLeft: 'auto',
    cursor: 'pointer',
  },
}));
