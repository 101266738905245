import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { Card } from '@frontend-monorepo/cyolo-ui';

import Body from './Body';
import Title from './Title';

const ResetPasswordWrapper: FC & {
  Title: typeof Title;
  Body: typeof Body;
} = ({ children }) => {
  const styles = useStyles();

  return <Card className={styles.root}>{children}</Card>;
};

ResetPasswordWrapper.Body = Body;
ResetPasswordWrapper.Title = Title;

export default ResetPasswordWrapper;

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    alignItems: 'center',
    margin: [['auto']],
    height: 'fit-content',
  },
});
