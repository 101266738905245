import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

const Body: FC = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.body}>{children}</div>;
};

export default Body;

const useStyles = createUseStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  sections: {
    display: 'flex',
    marginTop: 32,
    height: 240,
    gap: 64,
  },
});
