import { makeObservable, observable } from 'mobx';

import {
  PasswordPolicy,
  PasswordValidator,
  PolicyRulesValidator,
} from '../../../model';
import passwordApi, {
  default as PasswordAPI,
} from '../../../services/password-api';

import ResetPasswordScreenBase from './reset-password-screen-base';

class UpdatePasswordScreenState extends ResetPasswordScreenBase {
  /**
   * the password the user currently use.
   */
  currentPassword: string;

  /**
   * holds the number of days untill the current password expires
   */
  passwordDaysLeft: number;

  constructor() {
    super();
    this.currentPassword = '';

    makeObservable(this, {
      currentPassword: observable,
      passwordDaysLeft: observable,
    });
  }

  async bootstrap() {
    this.passwordDaysLeft = await PasswordAPI.getPasswordDaysLeft();
  }

  get doPasswordsMatch(): boolean {
    return this.validator.doPasswordsMatch(
      this.newPasswordState.newPassword,
      this.newPasswordState.confirmPassword,
    );
  }

  get expiryText(): string {
    const daysLeft = this.passwordDaysLeft;

    if (daysLeft > 0) return `Your password will expire in ${daysLeft} days`;

    if (daysLeft === 0) return 'Your password will expire today';

    return 'Your password has expired!';
  }

  get canSkip() {
    return this.passwordDaysLeft >= 0;
  }

  get isComplexityValid(): boolean {
    return this.validator?.doesMeetAll();
  }

  get isValid(): boolean {
    let areRulesPassed =
      this.isComplexityValid &&
      this.doPasswordsMatch &&
      !!this.currentPassword.length;

    if (this.policyValidator) {
      areRulesPassed =
        areRulesPassed &&
        this.policyValidator.minLengthRule.isValid &&
        this.policyValidator.maxLengthRule.isValid;

      if (this.enableBlackList) {
        areRulesPassed =
          areRulesPassed && this.policyValidator.blacklistRule.isValid;
      }

      if (this.policyValidator.state.isPending) {
        return false;
      }
    }

    return areRulesPassed;
  }

  /**
   * setCurrentPassword set the current password.
   * @param password
   */
  setCurrentPassword(password: string) {
    this.currentPassword = password;
  }

  async resetPassword(): Promise<void> {
    if (!this.isValid) {
      return;
    }

    await passwordApi.performUpdatePassword(
      this.currentPassword,
      this.newPasswordState.newPassword,
    );
  }

  async setupValidator(policyOptions?: {
    minComplexity: number;
    minLength: number;
    maxLength: number;
    enableBlacklist: boolean;
  }): Promise<void> {
    const rules = await passwordApi.getPasswordRules();
    this.validator = new PasswordValidator(rules, policyOptions?.minComplexity);

    if (policyOptions) {
      const { enableBlacklist, maxLength, minLength } = policyOptions;

      this.enableBlackList = enableBlacklist;
      this.policyValidator = new PolicyRulesValidator(minLength, maxLength);
    }
  }

  async setPasswordPolicy(p: PasswordPolicy) {
    const { enableBlacklist, maxLength, minComplexity, minLength } = p;

    await this.setupValidator({
      minComplexity,
      minLength,
      maxLength,
      enableBlacklist,
    });
  }
}

export default UpdatePasswordScreenState;
