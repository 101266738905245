import httpStatus from 'http-status';

import { autorun, makeAutoObservable, runInAction, when } from 'mobx';

import { MfaId, MfaMethod } from '@frontend-monorepo/cyolo-auth';
import { DataState } from '@frontend-monorepo/cyolo-store';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import AuthAPI from '../../../services/api/auth';
import validator from '../../../utils/validator';
import DataStoreContainer from '../../data/data';
import RequestIntervalStore from '../../shared/request-interval-store';
import UiStore from '../../ui-store';
import { StoreTransactionState } from '../types';

interface QrCodeState {
  qrUri: string;
  qrSecret: string;
}

class MfaViewState {
  constructor(
    private readonly dataStores: DataStoreContainer,
    private readonly uiStore: UiStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.smsRequestStore = new RequestIntervalStore();

    this.rawPhoneNumberPrefix = '';
    this.phoneNumberBody = '';
    this.mfaEnrollmentState = 'idle';
    this.smsSubmissionState = 'idle';
    this.qrCodeFetchingState = 'idle';

    this.qrCodeState = { qrUri: '', qrSecret: '' };

    // when the filteredAuthProvidersAllowedMfaMethods are not empty, set the first method to be the tab selection
    when(
      () => this.filteredAuthProvidersAllowedMfaMethods.length > 0,
      () => {
        // find if totp exists in filteredAuthProvidersAllowedMfaMethods
        const totpExistsInMfaMethods =
          this.filteredAuthProvidersAllowedMfaMethods.some(
            (mfaMethod) => mfaMethod.id === MfaId.TOTP,
          );

        // if exists make sure its the initial tab selection
        if (totpExistsInMfaMethods) {
          this.tabSelection = MfaId.TOTP;
          return;
        }

        // use first 1 if totp does not exist
        this.tabSelection = this.filteredAuthProvidersAllowedMfaMethods[0].id;
      },
    );

    // populate the phone number field if it already exists
    when(
      () => this.dataStores.userStore.state === 'done',
      () => {
        const { data } = this.dataStores.userStore;

        // check that user data exists
        if (!data) return;

        // validate the existing phone number
        if (!validator.validatePhoneNumber(data.phoneNumber)) return;

        // if valid, populate the existing fields
        this.rawPhoneNumberPrefix = data.phoneNumber.substr(
          0,
          this.phoneNumberPrefixLength,
        );
        this.phoneNumberBody = data.phoneNumber.substr(
          this.phoneNumberPrefixLength,
        );
      },
    );

    // update that last sms request time based on changes to the sumbission state
    autorun(() => {
      if (this.smsSubmissionState !== 'in-work') return;
      this.reqTimeDelta = Date.now();
    });

    // after any change of the enrollment state make sure to fetch new auth data
    autorun(() => {
      if (this.mfaEnrollmentState !== 'idle') return;
      this.dataStores.authDataStore.fetch();
    });
  }

  // mfaEnrollmentState describes the state of the store in regard to any
  // enrollment api action it performs in an async manner
  mfaEnrollmentState: StoreTransactionState;

  get organizationMfaMethodsState(): DataState {
    return this.dataStores.organizationMfaMethodsStore.state;
  }

  resetEnrollmentState(): void {
    this.mfaEnrollmentState = 'idle';
  }

  // a patchwork function to filter out unsupported mfa methods from backend response
  get filteredAuthProvidersAllowedMfaMethods(): MfaMethod[] {
    const { isLocalUser, isExternalUser } = this.dataStores.userIdentityStore;

    let mfaMethods: MfaMethod[] = [];
    if (
      isLocalUser &&
      Boolean(this.dataStores.userIdentityStore?.data.passwordProvider)
    ) {
      mfaMethods = [
        ...this.dataStores.userIdentityStore.data.passwordProvider
          .allowedMfaMethods,
      ];
    }

    if (isExternalUser) {
      mfaMethods = [...this.dataStores.userMfaMethodsStore.data];
    }

    return mfaMethods.sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  // tabSelectino is the selected mfa method id
  tabSelection: MfaId;

  get tabSelectionID(): number {
    return this.filteredAuthProvidersAllowedMfaMethods.findIndex(
      (method) => method.id === this.tabSelection,
    );
  }

  get selectedMfaMethod(): MfaMethod | undefined {
    return this.dataStores.organizationMfaMethodsStore.data.find(
      (method) => method.id === this.tabSelection,
    );
  }

  async handleDigitsInputCompletion(code: string): Promise<boolean> {
    try {
      this.mfaEnrollmentState = 'in-work';
      await AuthAPI.performMfaEnrollmentCodeValidation(this.tabSelection, code);
      runInAction(() => {
        this.mfaEnrollmentState = 'idle';
      });
      return true;
    } catch (error) {
      const { message = 'generic error' } = error;
      runInAction(() => {
        if (message.includes(httpStatus.UNAUTHORIZED)) {
          this.uiStore.showToast('Incorrect code', 'refused');
          this.resetEnrollmentState();
          return;
        }

        // general error
        this.mfaEnrollmentState = 'error';
        return false;
      });
    }
  }

  get isCurrentMethodEnrolled(): boolean {
    switch (this.tabSelection) {
      case MfaId.SMS:
        return this.dataStores.authDataStore.data?.smsEnrolled || false;
      case MfaId.TOTP:
        return this.dataStores.authDataStore.data?.totpEnrolled || false;
      case MfaId.EMAIL:
        return this.dataStores.authDataStore.data?.emailEnrolled || false;

      default:
        return false;
    }
  }

  get isSubmitButtonEnabled(): boolean {
    if (this.mfaEnrollmentState === 'in-work') return false;
    const {
      totpEnrolled = false,
      smsEnrolled = false,
      emailEnrolled = false,
    } = this.dataStores.authDataStore?.data || {};
    return [totpEnrolled, smsEnrolled, emailEnrolled].includes(true);
  }

  get allowDigitsInput(): boolean {
    return this.isCurrentMethodEnrolled;
  }

  // sms

  // rawPhoneNumberPrefix is the phone number as a raw
  // string without an prepended plus sign
  rawPhoneNumberPrefix: string;

  private phoneNumberPrefixLength = 4;

  updatePhoneNumberRawPrefix(change: string): void {
    if (!this.smsRequestStore.isTimeoutOver) return; // TODO: needs to be more generic
    if (change.length > this.phoneNumberPrefixLength) return;
    this.rawPhoneNumberPrefix = change;
  }

  // phoneNumberPrefix is the phone number prefix
  // after being prepended with a plus sign
  get phoneNumberPrefix(): string {
    const isOnlyPlusSign = this.rawPhoneNumberPrefix === '+';
    if (isOnlyPlusSign) return '';

    const isEmpty = this.rawPhoneNumberPrefix.length === 0;
    const startsWithPlusSign = this.rawPhoneNumberPrefix.startsWith('+');

    if (isEmpty || startsWithPlusSign) return this.rawPhoneNumberPrefix;
    return `+${this.rawPhoneNumberPrefix}`;
  }

  // phoneNumberBody is the body of the phone number
  phoneNumberBody: string;

  // updatePhoneNumberBody validates that the phone number body size is not
  // above 12 chracters and then update its body
  private phoneNumberLength = 12;

  updatePhoneNumberBody(change: string): void {
    if (!this.smsRequestStore.isTimeoutOver) return; // TODO: needs to be more generic
    if (change.length > this.phoneNumberLength) return;
    this.phoneNumberBody = change;
  }

  // fullPhoneNumber is the added phone number prefix and body
  get fullPhoneNumber(): string {
    return `${this.phoneNumberPrefix}${this.phoneNumberBody}`;
  }

  // canSendSms is a boolean value of wheter the current fullPhoneNumber
  // is a valid phone number
  get canSendSms(): boolean {
    // if the selected method is not sms return false
    if (this.selectedMfaMethod.id !== MfaId.SMS) return false;

    // if the state is not idle return false
    if (this.mfaEnrollmentState === 'in-work') return false;

    // if the state of the sms submission is in work, dont allow presses
    if (this.smsSubmissionState === 'in-work') return false;

    // check that their is a gap of at least 30s between requests
    if (!this.smsRequestStore.isTimeoutOver) return false;

    // validate the phone number using the validator module
    return validator.validatePhoneNumber(this.fullPhoneNumber);
  }

  // sms field is editable if there's no existing phone number to the user upon enrollment.
  get isSmsFieldDisabled(): boolean {
    return Boolean(this.dataStores.userStore?.data?.phoneNumber);
  }

  // smsSubmissionState is the state of the phone number submit transaction
  smsSubmissionState: StoreTransactionState;

  reqTimeDelta: number;

  async handleSmsSend(): Promise<void> {
    this.smsSubmissionState = 'in-work';

    const { didSucceed, remainingTime } =
      await AuthAPI.postPhoneNumberSubmission(this.fullPhoneNumber);

    // update sms request timeout remaining time
    runInAction(() => {
      if (remainingTime) {
        this.smsRequestStore.updateTimeDelta(remainingTime);
      }
    });

    // handle if the operation succeeded or not
    if (didSucceed) {
      runInAction(() => {
        this.uiStore.showToast('Sent sms message');
        this.smsSubmissionState = 'idle';
      });
      return;
    }

    runInAction(() => {
      this.uiStore.showToast('Sending sms message failed', 'refused');
      this.smsSubmissionState = 'error';
    });
  }

  // qrCodeFetchingState is the state of the qr code fetching transaction
  qrCodeFetchingState: StoreTransactionState;
  // qrCodeState is the state of the qrUri and qrSecret
  qrCodeState: QrCodeState;

  async fetchQrCode(): Promise<void> {
    this.qrCodeFetchingState = 'in-work';

    try {
      const rawUri = await AuthAPI.getQrCodeUri();
      const secret = new URLSearchParams(rawUri).get('secret') || '';

      runInAction(() => {
        this.qrCodeFetchingState = 'idle';

        this.qrCodeState = { qrUri: rawUri, qrSecret: secret };
      });
    } catch (error) {
      runInAction(() => {
        this.qrCodeFetchingState = 'error';

        this.uiStore.showToast(
          tryParsingBackendError(error, 'failed to get qr code'),
          'refused',
        );
      });
    }
  }

  handleQrCodeState(
    transactionState: StoreTransactionState,
    qrCodeState: QrCodeState,
  ): void {
    this.qrCodeFetchingState = transactionState;

    this.qrCodeState = qrCodeState;
  }

  selectTab(tabId: MfaId): void {
    // return if the state of the screen is in work
    if (this.mfaEnrollmentState === 'in-work') return;

    this.tabSelection = tabId;
  }

  public smsRequestStore: RequestIntervalStore;
}

export default MfaViewState;
