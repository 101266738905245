import { makeAutoObservable } from 'mobx';

import {
  IUserObject,
  UserAttributeName,
  UserAttributeObject,
} from '@frontend-monorepo/cyolo-identity';

const checkIfAcceptedLegalDoc = (
  attributes: Array<UserAttributeObject> | null,
) =>
  attributes?.some(
    (a) =>
      a.attribute_name === UserAttributeName.AcceptedLegalDocumentation &&
      typeof a.value === 'boolean' &&
      a.value,
  ) || false;

class AuthData {
  constructor(
    public enrolled = false,
    public totpEnabled = false,
    public totpEnrolled = false,
    public smsEnrolled = false,
    public emailEnrolled = false,
    public authenticated = false,
    public supervisor: IUserObject | null = null,
    public enabled = false,
    public acceptedLegalDoc = false,
    public mfaValidPolicies: string[] = [],
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  didPassPolicyMfa(policyId: string): boolean {
    return this.mfaValidPolicies.includes(policyId);
  }

  static fromRaw({
    enrolled,
    totp_enabled,
    totp_enrolled,
    sms_enrolled,
    email_enrolled,
    authenticated,
    supervisor,
    enabled,
    attributes,
    mfa_valid_policies,
  }: IUserObject): AuthData {
    return new AuthData(
      enrolled,
      totp_enabled,
      totp_enrolled,
      sms_enrolled,
      email_enrolled,
      authenticated,
      supervisor,
      enabled,
      checkIfAcceptedLegalDoc(attributes),
      mfa_valid_policies,
    );
  }

  get isEnrolledWithAnyMethod(): boolean {
    return this.totpEnrolled || this.smsEnrolled || this.emailEnrolled;
  }

  /* computed values */

  // isAnonymous uses the authData authenticated value to determine if
  // the user is anonymous or not
  get isAnonymous(): boolean {
    return this.authenticated === false;
  }

  // isMfaEnrolled specifically checks if at least one of the mfa methods is enrolled
  get isMfaEnrolled(): boolean {
    return this.totpEnrolled || this.smsEnrolled || this.emailEnrolled || false;
  }

  // isEnrolled uses the authData enrolled value to determine
  // if the user is enrolled and fallback to a faulty value
  get isEnrolled(): boolean {
    return this.enrolled || false;
  }

  // isEnabled uses the users enabled field to determine if he is
  // enabled
  get isEnabled(): boolean {
    return this.enabled === true;
  }
}

export default AuthData;
