export enum AppRoute {
  /* Redirect Screens */
  Root = '/root',
  Redirect = '/',

  /* Welcome */
  Welcome = '/welcome',

  /* Login */
  Login = '/login',

  /* Password */
  Password = '/password',

  /* IDPs */
  IDPs = '/idps',

  /* Mfa */
  Mfa = '/mfa',

  /* Awaiting Screens */
  NoPermission = '/unauthorized',
  Supervisor = '/supervisor',
  Disabled = '/disabled',
  ApprovalWaiting = '/approvals',

  /* Enroll Screens */
  Enroll = '/enroll',
  EnrollPersonalComputer = '/enroll/personal-computer',
  EnrollMfa = '/enroll/mfa',
  EnrollLegal = '/enroll/legal',

  UpdatePassword = '/update-password',
  RequestResetPassword = '/request-password-reset',
  InviteUser = '/invite-user',

  /* Supervisor Approval Screen */
  SupervisorApproval = '/supervisor-approval',

  /* Notify Idp */
  NotifyIdp = '/notify-idp',
}
