import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import {
  LoadingIndicator,
  LoadingIndicatorColors,
} from '@frontend-monorepo/cyolo-ui';

interface AppLoadingIndicatorProps {
  color?: LoadingIndicatorColors;
}

const useStyles = createUseStyles({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const AppLoadingIndicator: FC<AppLoadingIndicatorProps> = ({
  color = 'blue',
}) => {
  const styles = useStyles();
  return (
    <div className={styles.spinnerContainer}>
      <LoadingIndicator color={color} />
    </div>
  );
};

export default AppLoadingIndicator;
