import React, { FC, lazy, Suspense, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';

import { CompanyInfoStore } from '@frontend-monorepo/cyolo-company';
import { Toaster } from '@frontend-monorepo/cyolo-toast';

import UiStore from '../../stores/ui-store';
import {
  IdpScreen,
  LoginScreen,
  MfaScreen,
  PasswordScreen,
  RedirectScreen,
  RequestPasswordResetScreen,
  UpdatePasswordScreen,
} from '../Screens';
import {
  AppBackground,
  HistoryMonitor,
  NavBar,
  PoweredBy,
  ToastAdapter,
} from '../shared';
const EnrollScreen = lazy(() => import('../Screens/Enroll'));
const AwaitingApprovalScreen = lazy(() => import('../Screens/Waiting'));
const SupervisorApprovalScreen = lazy(
  () => import('../Screens/SupervisorApproval'),
);
const WelcomeScreen = lazy(() => import('../Screens/Welcome'));
const NotifyIdpScreen = lazy(() => import('../Screens/NotifyIdp'));

import { LoadingIndicator, LoadingOverlay } from '@frontend-monorepo/cyolo-ui';

import useMinimalHeight from '../../hooks/useMinimalHeight';
import { StoreProps } from '../../interface';
import { AppRoute } from '../../routes';
import InvitedUser from '../Screens/InvitedUser/InvitedUser';

interface StyleProps {
  isMinimalHeight: boolean;
}

const headerHeight = 70;
const useStyle = createUseStyles({
  appRoot: {
    position: 'absolute',
    top: headerHeight,
    bottom: ({ isMinimalHeight }: StyleProps) => (isMinimalHeight ? 0 : 96),
    left: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  poweredByContainer: {
    position: 'fixed',
    bottom: 0,
    paddingBottom: 32,
  },
});

/**
 * fetches initial stores data
 * @param uiStore - ui store for toasts
 * @param companyInfoStore
 */
const useFetchInitialData = (
  uiStore: UiStore,
  companyInfoStore: CompanyInfoStore,
) => {
  useAsyncEffect(async () => {
    try {
      await companyInfoStore.fetch();
    } catch (error) {
      uiStore.showToast('failed fetching initial data', 'refused');
    }
  }, []);
};

const App: FC<StoreProps> = ({
  rootStore: {
    uiStore,
    dataStores: { companyInfoStore },
  },
}) => {
  useFetchInitialData(uiStore, companyInfoStore);

  useEffect(() => {
    return autorun(() => {
      document.title = uiStore.appTitle;
    });
  }, []);

  const isMinimalHeight = useMinimalHeight();
  const style = useStyle({ isMinimalHeight });

  return (
    <>
      <Router>
        <HistoryMonitor />
        <AppBackground />
        <NavBar />
        <Toaster />
        <ToastAdapter />
        <div className={style.appRoot}>
          <Suspense fallback={<LoadingIndicator color="blue" />}>
            <Switch>
              <Route
                exact
                key="login"
                path={AppRoute.Login}
                component={LoginScreen}
              />
              <Route
                exact
                key="idps"
                path={AppRoute.IDPs}
                component={IdpScreen}
              />
              <Route
                exact
                key="setPassword"
                path={AppRoute.Password}
                component={PasswordScreen}
              />
              <Route
                exact
                key="welcome"
                path={AppRoute.Welcome}
                component={WelcomeScreen}
              />
              <Route
                exact
                key="notifyIdp"
                path={AppRoute.NotifyIdp}
                component={NotifyIdpScreen}
              />
              <Route
                exact
                key="mfa"
                path={AppRoute.Mfa}
                component={MfaScreen}
              />
              <Route
                key="enroll"
                path={AppRoute.Enroll}
                component={EnrollScreen}
              />
              <Route
                exact
                key="supervisor"
                path={AppRoute.Supervisor}
                component={AwaitingApprovalScreen}
              />
              <Route
                exact
                key="disabled"
                path={AppRoute.Disabled}
                component={AwaitingApprovalScreen}
              />
              <Route
                exact
                key="unauthorized"
                path={AppRoute.NoPermission}
                component={AwaitingApprovalScreen}
              />
              <Route
                key="approvals"
                path={AppRoute.ApprovalWaiting}
                component={AwaitingApprovalScreen}
              />
              <Route
                exact
                key="redirect"
                path={AppRoute.Redirect}
                component={RedirectScreen}
              />
              <Route
                exact
                key="updatePassword"
                path={AppRoute.UpdatePassword}
                component={UpdatePasswordScreen}
              />
              <Route
                exact
                key="resetPassword"
                path={AppRoute.RequestResetPassword}
                component={RequestPasswordResetScreen}
              />
              <Route
                exact
                key="supervisorApproval"
                path={AppRoute.SupervisorApproval}
                component={SupervisorApprovalScreen}
              />
              <Route
                exact
                key="inviteUser"
                path={`${AppRoute.InviteUser}/:token`}
                component={InvitedUser}
              />
              <Route path="*" component={() => <h1>404 route error</h1>} />
            </Switch>
          </Suspense>
          {!isMinimalHeight && (
            <div className={style.poweredByContainer}>
              <PoweredBy width={440} />
            </div>
          )}
        </div>
      </Router>
      {uiStore.showLoadingOverlay && <LoadingOverlay />}
    </>
  );
};

export default inject('rootStore')(observer(App));
