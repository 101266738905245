import {
  PasswordPolicy,
  PasswordValidator,
  PolicyRulesValidator,
} from '../../../model';
import passwordApi from '../../../services/password-api';

import NewPasswordState from './new-password-state';
import ResetPasswordScreenBase from './reset-password-screen-base';

class ResetPasswordScreenState extends ResetPasswordScreenBase {
  passwordPolicy: PasswordPolicy;

  constructor() {
    super();
  }

  get isComplexityValid(): boolean {
    return this.validator?.doesMeetAll();
  }

  get doPasswordsMatch(): boolean {
    return this.validator.doPasswordsMatch(
      this.newPasswordState.newPassword,
      this.newPasswordState.confirmPassword,
    );
  }

  get isValid(): boolean {
    let areRulesPassed =
      this.isComplexityValid &&
      this.doPasswordsMatch &&
      this.policyValidator.minLengthRule.isValid &&
      this.policyValidator.maxLengthRule.isValid;

    if (this.enableBlackList) {
      areRulesPassed =
        areRulesPassed && this.policyValidator.blacklistRule.isValid;
    }

    if (this.policyValidator?.state.isPending) {
      return false;
    }

    return areRulesPassed;
  }

  setPasswordPolicy(p: PasswordPolicy) {
    this.passwordPolicy = p;
    const { enableBlacklist, maxLength, minComplexity, minLength } = p;

    this.setupValidator({
      minComplexity,
      minLength,
      maxLength,
      enableBlacklist,
    });
  }

  async setupValidator(policyOptions?: {
    minComplexity: number;
    minLength: number;
    maxLength: number;
    enableBlacklist: boolean;
  }): Promise<void> {
    const rules = await passwordApi.getPasswordRules();
    this.validator = new PasswordValidator(rules, policyOptions?.minComplexity);

    if (policyOptions) {
      const { enableBlacklist, maxLength, minLength } = policyOptions;

      this.enableBlackList = enableBlacklist;
      this.policyValidator = new PolicyRulesValidator(minLength, maxLength);
    }
  }

  async resetPassword(): Promise<void> {
    if (!this.isValid) {
      return;
    }

    await passwordApi.performResetPassword(this.newPasswordState.newPassword);
  }

  resetState() {
    this.newPasswordState = new NewPasswordState();
  }
}

export default ResetPasswordScreenState;
