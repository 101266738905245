import React, { FC, ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

import { when } from 'mobx';
import { observer } from 'mobx-react';

import { Card } from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../../hooks';
import { AppRoute } from '../../../../routes';
import pageRedirect from '../../../../services/page-redirect';
import { LoginEndpoint } from '../../../../stores/data/user-identity-store';
import { AppLoadingIndicator } from '../../../shared';
import LoginForm from '../LoginForm';

const LoginScreen: FC = () => {
  const { stateStore, dataStores } = useRootStore();
  const { loginScreenState } = stateStore;

  const history = useHistory();

  useEffect(() => {
    const checkIsLocalStoredUserDispose = when(
      () => Boolean(dataStores.userIdentityStore.data.username),
      () => {
        history.push(AppRoute.Welcome);
      },
    );

    const checkIsAuthenticatedUserDispose = when(
      () => !dataStores.authDataStore.data.isAnonymous,
      () => {
        pageRedirect.performPageRedirect();
      },
    );

    return () => {
      checkIsLocalStoredUserDispose();
      checkIsAuthenticatedUserDispose();
    };
  }, []);

  const styles = useStyles();

  const confirmNextStep = async () => {
    try {
      const loginEndpoint = await loginScreenState.performLoginIdentity();

      redirectToNextStep(loginEndpoint);
    } catch (error) {
      loginScreenState.setErrorMessage(
        tryParsingBackendError(error, 'Invalid username or password'),
      );
    }
  };

  const redirectToNextStep = (loginEndpoint: string) => {
    if (
      loginEndpoint === LoginEndpoint.Password ||
      dataStores.userIdentityStore.data.passwordProvider
    ) {
      history.push(AppRoute.Password);

      return;
    }

    if (dataStores.userIdentityStore.singleExternalIdp) {
      pageRedirect.performPageRedirect(
        dataStores.userIdentityStore.singleExternalIdp.url,
      );

      return;
    }

    history.push(AppRoute.IDPs);
  };

  const renderCardContent = (): ReactElement => {
    switch (loginScreenState.loginState) {
      case 'in-work':
        return <AppLoadingIndicator />;
      case 'idle':
        return (
          <LoginForm
            username={loginScreenState.username}
            onChange={(e) => loginScreenState.setUsername(e.target.value)}
            onNextStepClick={confirmNextStep}
            isNextStepActive={loginScreenState.isNextStepButtonEnabled}
            errorMessage={loginScreenState.errorMessage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.loginViewRoot}>
      <div className={styles.cardContainer}>
        <Card className={styles.card}>
          <div className={styles.cardContent}>{renderCardContent()}</div>
        </Card>
      </div>
    </div>
  );
};

export default observer(LoginScreen);

const useStyles = createUseStyles({
  loginViewRoot: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardContainer: {
    width: 440,
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: 440,
    padding: '80px 0 !important',
  },
});
