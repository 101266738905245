import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { inject } from 'mobx-react';

import RootStore from '../../../stores/root-store';

interface HistoryMonitorProps {
  rootStore?: RootStore;
}

// HistoryMonitor is a empty component in charge of syncing react router to
// the MobX store containing the location of the current page
const HistoryMonitor: FC<HistoryMonitorProps> = ({ rootStore }) => {
  const location = useLocation();
  useEffect(() => {
    rootStore.locationStore.updateLocation(location.pathname);
  }, [location.pathname]);
  return null;
};

export default inject('rootStore')(HistoryMonitor);
