export enum ApprovalState {
  pending = 'false',
  approved = 'approved',
  passPrepending = 'passPrepending',
  prePending = 'prePending',
  rejected = 'error',
}

export interface Approval {
  is_approved: boolean;
}

export interface ValidationError {
  failure_reason: string;
  remediation_link: string;
  retry_link: string;
}

export interface SupervisorApprovalTokenResponse {
  username: string;
  mappingName: string;
}

export interface PendingApproval {
  approvalState: ApprovalState;
  expirationTime: string;
}

export interface PendingApprovalResponse {
  approval_state: ApprovalState;
  expiration_time: string;
}
