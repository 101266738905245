import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

import {
  Button,
  CyTheme,
  Image,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { AuthProvider } from '../../../../model';
import { AppRoute } from '../../../../routes';
import { Idps } from '../../../../stores/data/user-identity-store';

interface IdpsListProps {
  idps: Idps;
  onClick: (provider: AuthProvider) => void;
}

const IdpsList: FC<IdpsListProps> = ({ idps, onClick }) => {
  const theme = useThemeSafe();
  const styles = useStyle({ theme });

  const history = useHistory();

  const handleClickPasswordProvider = (provider: AuthProvider) => () => {
    onClick(provider);
    history.push(AppRoute.Password);
  };

  const handleClickExternalProvider = (provider: AuthProvider) => () => {
    onClick(provider);
  };

  return (
    <div className={styles.authProviderBtnList}>
      {idps.passwordIdps.length > 0 &&
        idps.passwordIdps.map((provider) => (
          <Button
            variant="secondary"
            type="button"
            key={`${provider.id}_${provider.name}`}
            className={styles.authProviderButton}
            icon={
              provider.icon && (
                <Image
                  alt={`${provider.name}'s icon`}
                  src={provider.icon}
                  className={styles.icon}
                />
              )
            }
            onClick={handleClickPasswordProvider(provider)}
          >
            {provider.name}
          </Button>
        ))}
      {idps.externalIdps.map((provider) => (
        <Button
          variant="secondary"
          type="button"
          key={`${provider.id}_${provider.name}`}
          className={styles.authProviderButton}
          icon={
            provider.icon && (
              <Image
                alt={`${provider.name}'s icon`}
                src={provider.icon}
                className={styles.icon}
              />
            )
          }
          onClick={handleClickExternalProvider(provider)}
        >
          {provider.name}
        </Button>
      ))}
    </div>
  );
};

export default IdpsList;

const useStyle = createUseStyles((theme: CyTheme) => ({
  authProviderBtnList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: 136,
    '& > :first-child': {
      marginTop: '0 !important',
    },
  },
  passwordIdpsMessage: {
    textAlign: 'center',
  },
  authProviderButton: {
    width: '100%',
    marginTop: 8,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    gap: 8,
    borderRadius: theme.shape.borderRadiusLight,
    paddingLeft: 32,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: theme.palette.white.color,

    '&>p': {
      maxWidth: '150px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  icon: {
    width: 12,
    height: 12,
  },
  idpsHeader: { width: '100%', textAlign: 'center' },
  divider: {
    marginTop: 16,
    marginBottom: 8,
    backgroundColor: theme.palette.background.color,
  },
}));
