import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { inject, observer } from 'mobx-react';

import { Button, CyTheme } from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../../../hooks/useRootStore';
import { useShowPasswordError } from '../../../../hooks';
import PasswordAndConfirm from '../../../../shared/PasswordAndConfirm';
import { PasswordType } from '../../../UpdatePassword/enum';

const PasswordSection: FC = () => {
  const {
    uiStore,
    stateStore: { resetPasswordScreenState, requestPasswordResetState },
  } = useRootStore();

  const { showError, errorMessage, setSubmitError } = useShowPasswordError({
    confirmPassword: resetPasswordScreenState.newPasswordState.confirmPassword,
    doPasswordsMatch: resetPasswordScreenState.doPasswordsMatch,
    isMinLengthValid:
      resetPasswordScreenState.policyValidator?.minLengthRule.isValid,
  });

  const styles = useStyles();

  useEffect(() => {
    return () => resetPasswordScreenState.resetState();
  }, []);

  const handleReset = async () => {
    if (!resetPasswordScreenState.isValid) {
      return;
    }

    try {
      uiStore.setLoadingState('pending');
      // send request here
      await resetPasswordScreenState.resetPassword();
      requestPasswordResetState.nextStep();
    } catch (error) {
      setSubmitError(
        tryParsingBackendError(error, 'Failed to update password'),
      );
    } finally {
      uiStore.setLoadingState('done');
    }
  };

  const handlePasswordChange = (
    type: PasswordType,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    switch (type) {
      case PasswordType.New:
        resetPasswordScreenState.newPasswordState.setNewPassword(
          e.target.value,
        );
        break;
      case PasswordType.Confirm:
        resetPasswordScreenState.newPasswordState.setConfirmPassword(
          e.target.value,
        );
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <PasswordAndConfirm
        newPasswordValue={resetPasswordScreenState.newPasswordState.newPassword}
        confirmPasswordValue={
          resetPasswordScreenState.newPasswordState.confirmPassword
        }
        handleNewPasswordChange={(e) =>
          handlePasswordChange(PasswordType.New, e)
        }
        handleConfirmPasswordChange={(e) =>
          handlePasswordChange(PasswordType.Confirm, e)
        }
        errorMessage={errorMessage}
        showError={showError}
      />
      <div className={styles.buttonWrap}>
        <Button
          variant="primary"
          onClick={handleReset}
          disabled={!resetPasswordScreenState.isValid}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default inject('rootStore')(observer(PasswordSection));

const useStyles = createUseStyles((theme: CyTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
  buttonWrap: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
}));
