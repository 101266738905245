const performPageRedirect = (path = `${window.origin}/v1/redirect`): void => {
  window.location.assign(path);
};

const performPageRedirectUnauthorized = (): void => {
  window.location.assign(`${window.origin}/unauthorized`);
};

const performApprovalApprovedPageRedirect = (): void => {
  const id = new URLSearchParams(location.search).get('id');
  if (!id) return;
  const path = `${window.origin}/v1/approvals/${id}/redirect`;
  window.location.assign(path);
};

export default {
  performPageRedirect,
  performApprovalApprovedPageRedirect,
  performPageRedirectUnauthorized,
};
