import React, { FC } from 'react';
import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import { useRootStore } from '../../../hooks/useRootStore';
import FinishStep from '../RequestPasswordReset/FinishStep';

import CreateNewPassword from './CreateNewPassword';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
});

const InvitedUserScreen: FC = () => {
  const {
    stateStore: { invitedUserScreenState: store },
    uiStore,
  } = useRootStore();

  const styles = useStyles();

  // reset store when user moves from the page (back to /login, etc.)
  useEffect(() => {
    return () => {
      uiStore.setLoadingState('done');
      store.reset();
    };
  }, []);

  if (uiStore.loadingStateOverride === 'error') {
    return <FinishStep type="error" />;
  }

  const renderStep = () => {
    switch (store.currentStep) {
      case 1:
        return <CreateNewPassword />;
      case 2:
        return <FinishStep redirectPath="/cyolo/v1/logout" />;
    }

    return null;
  };

  return <div className={styles.root}>{renderStep()}</div>;
};

export default observer(InvitedUserScreen);
