import qs from 'qs';

import { MfaId, MfaMethod } from '@frontend-monorepo/cyolo-auth';
import httpClient from '@frontend-monorepo/http-client';

import { AuthProvider, PasswordPolicy } from '../../model';

import {
  ExternalResetPasswordResponse,
  ProviderResponse,
} from './auth/response';
import Endpoints from './endpoints';

const availableResetMethods = async (username: string): Promise<MfaId[]> => {
  const { data } = await httpClient.post<MfaMethod[]>(Endpoints.methods, {
    username,
  });
  return data
    .filter((method) => method.id !== MfaId.TOTP)
    .map((method) => method.id)
    .sort();
};

const userIdentityProvider = async (
  username: string,
): Promise<AuthProvider> => {
  const { data } = await httpClient.post<ProviderResponse>(
    Endpoints.userProvider,
    {
      username,
    },
  );

  const {
    id,
    submitForm,
    icon,
    name,
    url,
    allowed_mfa_methods,
    password_policy,
    visible,
  } = data;

  return new AuthProvider(
    String(id),
    submitForm,
    icon,
    url,
    name,
    allowed_mfa_methods.map((method) => MfaMethod.fromRaw(method)),
    PasswordPolicy.fromRaw(password_policy),
    visible,
  );
};

const requestResetMethod = async (
  method: string,
  username: string,
): Promise<number> => {
  try {
    const { data } = await httpClient.post(
      Endpoints.sendOtp,
      qs.stringify({
        method,
        username,
      }),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    const { remainingTime } = data as ExternalResetPasswordResponse;
    return remainingTime;
  } catch (error) {
    // generic error
    if (error?.response?.status === 429) {
      // axios error with status code 429
      const { data } = error.response;
      // return the result
      const { remainingTime } = data as ExternalResetPasswordResponse;
      return remainingTime;
    } else {
      throw error;
    }
  }
  //TODO returns remaining tries, remaining time, error if sending fails
};

const postMfaCode = async (code: string) => {
  try {
    await httpClient.post(Endpoints.codeValidation, qs.stringify({ code }), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return;
  } catch (error) {
    // generic error
    if (error?.response?.status === 429) {
      // axios error with status code 429
      window.location.assign('/');
      return;
    }

    throw error;
  }
};

export default {
  availableResetMethods,
  userIdentityProvider,
  requestResetMethod,
  postMfaCode,
};
