import React, { FC } from 'react';
import Particles from 'react-tsparticles';

import ParticleOptions from './options';

import './AppBackground.scss';

const AppBackground: FC = () => (
  <div className="AppBackground-root">
    <Particles id="tsparticles" options={ParticleOptions as any} />
  </div>
);

export default AppBackground;
