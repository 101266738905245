import ms from 'ms';

import { autorun } from 'mobx';

import { AppRoute } from '../routes';
import LocationStore from '../stores/location-store';
import UiStore from '../stores/ui-store';

// LoginScreenQueryParamsErrorHandler is in charge of handling
// errors passed by the backend the frontend through query params
// when the login app is in the login screen
class LoginScreenQueryParamsErrorHandler {
  uiStore: UiStore;

  locationStore: LocationStore;

  constructor(uiStore: UiStore, locationStore: LocationStore) {
    this.uiStore = uiStore;
    this.locationStore = locationStore;

    // check for error in querystring when in login route
    autorun(() => {
      // work only in login screen
      if (this.locationStore.currentLocation !== AppRoute.Login) return;
      const urlParams = new URLSearchParams(window.location.search);
      const errorMessage = urlParams.get('error');
      if (errorMessage === null) return;

      setTimeout(() => {
        this.uiStore.showToast(
          'There was an error logging in, please contact your admin',
          'refused',
        );
      }, ms('1s'));
    });
  }
}

export default LoginScreenQueryParamsErrorHandler;
