import { action, makeObservable, observable } from 'mobx';

import { AppRoute } from '../routes';

class LocationStore {
  currentLocation: AppRoute;

  constructor() {
    makeObservable(this, {
      currentLocation: observable,
      updateLocation: action.bound,
    });

    this.currentLocation = AppRoute.Redirect;
  }

  // updateLocation is in charge of updating the current route
  // and validating new locations given to it
  updateLocation(newLocation: string): void {
    // validate that the location exists in the app
    const newLocIsValidRoute =
      Object.values<string>(AppRoute).includes(newLocation);

    if (!newLocIsValidRoute) {
      return;
    }

    this.currentLocation = newLocation as AppRoute;
  }
}

export default LocationStore;
