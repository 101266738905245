import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { inject, observer } from 'mobx-react';

import { Text } from '@frontend-monorepo/cyolo-ui';

import useMinimalHeight from '../../../../hooks/useMinimalHeight';
import { StoreProps } from '../../../../interface';

interface StyleProps {
  isMinimalHeight: boolean;
}

const useStyle = createUseStyles({
  root: {
    textAlign: 'center',
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: ({ isMinimalHeight }: StyleProps) =>
      isMinimalHeight ? '' : 'absolute',
    bottom: ({ isMinimalHeight }: StyleProps) => (isMinimalHeight ? 0 : 264),
  },
  subText: {
    marginTop: 25,
    width: 360,
    lineHeight: '1.6',
  },
});

const Titles: FC<StoreProps> = ({
  rootStore: {
    stateStore: { mfaScreenState },
  },
}) => {
  const isMinimalHeight = useMinimalHeight();
  const style = useStyle({ isMinimalHeight });

  return (
    <div className={style.root}>
      <Text variant="login-sub-title">{mfaScreenState.loginSubtitle}</Text>
      <div className={style.subText}>
        <Text variant="body">{mfaScreenState.verificationBodyText}</Text>
      </div>
    </div>
  );
};

export default inject('rootStore')(observer(Titles));
