import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

const Title: FC = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.header}>{children}</div>;
};

export default Title;

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 32,
    gap: 4,
  },
});
