import { makeAutoObservable, reaction } from 'mobx';

import { MfaId } from '@frontend-monorepo/cyolo-auth';
import { Validator } from '@frontend-monorepo/cyolo-utils';

import { Step } from '../../../components/Screens/RequestPasswordReset/Stepper/Circle';
import API from '../../../services/api/resetPassword';
import DataStoreContainer from '../../data/data';
import RequestIntervalStore from '../../shared/request-interval-store';

export const ResetSteps = new Map<number, string>([
  [1, 'Request password change'],
  [2, 'Choose method'],
  [3, 'Verification code'],
  [4, 'Create new password'],
  [5, 'Finish'],
]);

class RequestPasswordResetState {
  currentStep: number;
  availableMethods: MfaId[];
  selectedMfaMethod: MfaId;
  username: string;

  constructor(
    private readonly dataStores: DataStoreContainer,
    public requestStore: RequestIntervalStore = new RequestIntervalStore(),
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.dataStores.userIdentityStore.data.username,
      (username) => {
        this.username = username;
      },
    );

    this.reset();
  }

  get steps(): number[] {
    const keys: number[] = [];
    ResetSteps.forEach((value, key) => keys.push(key));
    return keys;
  }

  stepState(step: number): Step {
    if (step < this.currentStep) {
      return 'done';
    }

    if (step === this.currentStep) {
      return 'current';
    }

    return 'future';
  }

  description(key: number): string {
    return ResetSteps.get(key);
  }

  nextStep() {
    this.currentStep = Math.min(this.currentStep + 1, 5);
  }

  backStep() {
    this.currentStep = Math.max(1, this.currentStep - 1);
  }

  setUsername(value: string) {
    this.username = value;
  }

  get usernameValid(): boolean {
    return Validator.notEmpty(this.username);
  }

  async submitUserResetRequest() {
    this.availableMethods = await API.availableResetMethods(this.username);
    this.nextStep();
  }

  async chooseMethod(method: MfaId) {
    this.selectedMfaMethod = method;
    await this.submitMethod();
    this.nextStep();
  }

  async submitMethod() {
    const remainingTime = await API.requestResetMethod(
      this.selectedMfaMethod,
      this.username,
    );
    this.requestStore.updateTimeDelta(remainingTime);
  }

  async submitOtp(code: string) {
    await API.postMfaCode(code);
    this.nextStep();
  }

  get canResendCode(): boolean {
    return !Boolean(this.requestStore.formattedtimeUntilSmsCanBeSentAgain);
  }

  get showStepper(): boolean {
    return this.currentStep !== 5;
  }

  reset() {
    this.currentStep = 1;
    this.username = this.dataStores.userIdentityStore.data.username;
    this.availableMethods = [];
    this.selectedMfaMethod = undefined;
  }
}

export default RequestPasswordResetState;
