import React, {
  CSSProperties,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import useAsyncEffect from 'use-async-effect';

import { useThemeSafe } from '@frontend-monorepo/cyolo-ui';

import useStyles from './DigitsInput.styles';

type ResetDigits = boolean;

interface DigitsInputProps {
  numberOfKeys: number;
  onCompletion?: (code: string) => Promise<ResetDigits>;
  active?: boolean;
  digitFontSize?: CSSProperties['fontSize'];
}

const KeyCodes = {
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  BACKSPACE: 8,
};

const rightPadDigits = (digits: string[], length: number): string[] => {
  const arr = Array(length).fill('');
  digits.slice(0, length).forEach((digit, i) => {
    arr[i] = digit;
  });
  return arr;
};

const DigitsInput: FC<DigitsInputProps> = ({
  numberOfKeys,
  active = true,
  digitFontSize,
  onCompletion,
}) => {
  const containerDivRef = useRef<HTMLDivElement>();
  const [digits, setDigits] = useState<string[]>(Array(numberOfKeys).fill(''));

  const theme = useThemeSafe();
  const styles = useStyles({ theme, digitFontSize });

  const getInputs = useCallback(() => {
    if (!containerDivRef.current) return [];
    return Array.from(containerDivRef.current.getElementsByTagName('input'));
  }, [containerDivRef]);

  const nextEmptyFieldIndex = useMemo(() => {
    return digits.indexOf('');
  }, [digits]);

  /** handlers */

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    switch (e.keyCode) {
      case KeyCodes.BACKSPACE:
        if (!digits[index] && index !== 0) {
          const currentDigits = [...digits];
          currentDigits[index - 1] = '';
          setDigits(currentDigits);
          focusOnPreviousDigit(index);
        }
        break;
      case KeyCodes.LEFT_ARROW:
        focusOnPreviousDigit(index);
        break;
      case KeyCodes.RIGHT_ARROW:
        focusOnNextDigit(index);
        break;
      default:
        break;
    }
  };

  const handlePaste = ({ clipboardData }: React.ClipboardEvent) => {
    const text = clipboardData.getData('text');
    setDigits(rightPadDigits([...text], numberOfKeys));
    focusOnNextEmptyField();
  };

  const handleReset = () => {
    setDigits(Array(numberOfKeys).fill(''));
    focusOnField(0);
  };

  /** focus operations */

  const focusOnField = (index: number) => {
    if (!getInputs()[index]) return;
    getInputs()[index].focus();
  };

  const focusOnNextEmptyField = () => {
    !isDigitsFull && focusOnField(nextEmptyFieldIndex);
  };

  const focusOnNextDigit = (index: number) => {
    focusOnField(Math.min(numberOfKeys - 1, index + 1));
  };

  const focusOnPreviousDigit = (index: number) => {
    focusOnField(Math.max(0, index - 1));
  };

  const isDigitsFull = nextEmptyFieldIndex === -1;

  /** effects */

  useAsyncEffect(async () => {
    if (isDigitsFull) {
      const reset = await onCompletion?.(digits.join(''));
      !reset && handleReset();
    }
  }, [digits]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (isDigitsFull) return;

    const currentDigits = [...digits];
    const { value } = e.target;

    const num = value[0]; // in case we need to override selected input when user selects it using arrows

    if (/\D/g.test(num)) {
      return;
    }

    currentDigits[index] = num;
    setDigits(currentDigits);
    focusOnNextDigit(index);
  };

  return (
    <div className={styles.root} ref={containerDivRef}>
      {digits.map((digit, i) => (
        <input
          key={i}
          className={styles.digit}
          onClick={() => {
            focusOnNextEmptyField();
          }}
          autoFocus={!digits[0].length && i === 0}
          value={digit}
          onKeyDown={(e) => handleKeyPress(e, i)}
          data-id={i}
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={(e) => handleChange(e, i)}
          readOnly={!active}
          onPaste={handlePaste}
        />
      ))}
    </div>
  );
};

export default DigitsInput;
