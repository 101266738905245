import React, { FC, useMemo } from 'react';

import { inject, observer } from 'mobx-react';

import { Logo } from '@frontend-monorepo/cyolo-shell';
import { ActionIconProps, MinimalNavBar } from '@frontend-monorepo/cyolo-ui';

import pageRedirect from '../../../services/page-redirect';
import RootStore from '../../../stores/root-store';

import './NavBar.scss';

interface NavBarProps {
  rootStore?: RootStore;
}

// TODO: refactor out of here
const LogoutButton: ActionIconProps = {
  text: 'Logout',
  onClick: (): void => {
    pageRedirect.performPageRedirect('/cyolo/v1/logout');
  },
};

// NavBar is the upper screen nav bar stylized component
const NavBar: FC<NavBarProps> = ({ rootStore }) => {
  const {
    companyInfoStore,
    authDataStore: authStore,
    userStore,
  } = rootStore.dataStores;

  const userDropDownSections = useMemo(() => {
    const buttons: ActionIconProps[] = [];

    if (authStore.data?.authenticated) {
      buttons.push(LogoutButton);
    }

    return [buttons];
  }, [authStore.data?.authenticated]);

  return (
    <div className="NavBar-root">
      <MinimalNavBar
        variant="user-nav"
        logo={<Logo rootStore={{ companyInfoStore }} type="white" />}
        title=""
        userName={userStore.data?.name}
        firstName={userStore.data?.firstName}
        lastName={userStore.data?.lastName}
        userEmail={userStore.data?.email}
        userDropdownSections={userDropDownSections}
      />
    </div>
  );
};

export default inject('rootStore')(observer(NavBar));
