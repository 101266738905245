import { isMobile } from 'react-device-detect';

import { action, computed, makeObservable, observable } from 'mobx';

import { CompanyInfoStore } from '@frontend-monorepo/cyolo-company';
import { DataState } from '@frontend-monorepo/cyolo-store';
import { BaseToast, ToastType } from '@frontend-monorepo/cyolo-toast';

class UiStore {
  // activeToast is the current shown toast in the ui
  activeToast?: BaseToast;

  /**
   loadingStateOverride is an observable that overrides the loadingState computed getter
   */
  loadingStateOverride: DataState;

  // isMobile is a computed function to check if were running on a mobile device
  get isMobile(): boolean {
    return isMobile;
  }

  // showToast sets the current active toast with a new one
  showToast(message: string, type: ToastType = 'approved'): void {
    this.activeToast = { message, type };
  }

  /**
   * returns the app title that should be set in its
   * navbar & document title
   */
  get appTitle(): string {
    const store = this.companyInfoStore;
    if (store.state !== 'done') return '';

    if (store.data.headerPlaceHolder) {
      return `${store.data.headerPlaceHolder} Login`;
    }
    return 'Cyolo Login';
  }

  /**
   * decides if the loading overlay should be shown
   */
  get showLoadingOverlay() {
    return this.loadingStateOverride === 'pending';
  }

  /**
   setLoadingState is a setter that sets loadingStateOverride which overrides the loadingState
   *
   * @param state
   */
  setLoadingState(state: DataState): void {
    this.loadingStateOverride = state;
  }

  constructor(private readonly companyInfoStore: CompanyInfoStore) {
    makeObservable(this, {
      activeToast: observable,
      isMobile: computed,
      showToast: action.bound,
      appTitle: computed,
      loadingStateOverride: observable,
      showLoadingOverlay: computed,
      setLoadingState: action.bound,
    });

    this.activeToast = undefined;
  }
}

export default UiStore;
