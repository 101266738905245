/**
 * a small gift for ppl who dont like tarnery in className inline assignment :)
 * recieve a condition and a class string.
 * if condition met reutrn the class name.
 * otherwise return empty string
 */
export default function useClass(
  condition: boolean,
  targetClass: string,
): string {
  return condition ? targetClass : '';
}
