import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { inject, observer } from 'mobx-react';

import {
  Button,
  CyTheme,
  PasswordField,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../../../hooks/useRootStore';
import pageRedirect from '../../../../../services/page-redirect';
import { useShowPasswordError } from '../../../../hooks';
import PasswordAndConfirm from '../../../../shared/PasswordAndConfirm';
import { PasswordType } from '../../enum';

const PasswordsSection: FC = () => {
  const {
    uiStore,
    stateStore: { updatePasswordScreenState },
    dataStores: { userStore },
  } = useRootStore();

  const theme = useThemeSafe();

  const canSkip =
    !userStore.data.mustChangePassword && updatePasswordScreenState.canSkip;

  const { showError, errorMessage, setSubmitError } = useShowPasswordError({
    confirmPassword: updatePasswordScreenState.newPasswordState.confirmPassword,
    doPasswordsMatch: updatePasswordScreenState.doPasswordsMatch,
    isMinLengthValid:
      updatePasswordScreenState.policyValidator?.minLengthRule.isValid,
  });

  const styles = useStyles({ theme });

  const handlePasswordChange = (
    type: PasswordType,
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    switch (type) {
      case PasswordType.Current:
        updatePasswordScreenState.setCurrentPassword(e.target.value);
        return;
      case PasswordType.New:
        updatePasswordScreenState.newPasswordState.setNewPassword(
          e.target.value,
        );
        return;
      case PasswordType.Confirm:
        updatePasswordScreenState.newPasswordState.setConfirmPassword(
          e.target.value,
        );
        return;
    }
  };

  const handleSkip = () => {
    pageRedirect.performPageRedirect();
  };

  const handleSubmit = async () => {
    try {
      uiStore.setLoadingState('pending');
      await updatePasswordScreenState.resetPassword();
      pageRedirect.performPageRedirect();
    } catch (err) {
      setSubmitError(tryParsingBackendError(err, 'Failed to update password'));
    } finally {
      uiStore.setLoadingState('done');
    }
  };

  return (
    <div className={styles.wrapper}>
      <PasswordField
        placeholder="Current password"
        value={updatePasswordScreenState.currentPassword}
        onChange={(e) => handlePasswordChange(PasswordType.Current, e)}
        className={styles.input}
      />
      <PasswordAndConfirm
        newPasswordValue={
          updatePasswordScreenState.newPasswordState.newPassword
        }
        confirmPasswordValue={
          updatePasswordScreenState.newPasswordState.confirmPassword
        }
        handleNewPasswordChange={(e) =>
          handlePasswordChange(PasswordType.New, e)
        }
        handleConfirmPasswordChange={(e) =>
          handlePasswordChange(PasswordType.Confirm, e)
        }
        errorMessage={errorMessage}
        showError={showError}
      />
      <div className={styles.buttonWrap}>
        {canSkip && (
          <Button
            type="button"
            id="skip"
            variant="secondary"
            onClick={handleSkip}
          >
            Skip
          </Button>
        )}
        <Button
          type="submit"
          id="submit"
          variant="primary"
          onClick={handleSubmit}
          disabled={!updatePasswordScreenState.isValid}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default inject('rootStore')(observer(PasswordsSection));

const useStyles = createUseStyles((theme: CyTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
  input: {
    width: 200,

    '&.error': {
      borderColor: theme.palette.error.color,

      '& > input': {
        color: theme.palette.error.color,
      },
    },
  },
  buttonWrap: {
    display: 'flex',
    columnGap: 16,
    marginTop: 8,
  },
}));
