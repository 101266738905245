import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

import {
  Button,
  CyTheme,
  LoadingIndicator,
  StandaloneLoadingIndicator,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { useClass } from '../../../../hooks';
import { DigitsInput } from '../../../../shared';

const rowWidth = 220;
const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 0 auto',
    minWidth: 340,
    height: 180,
  },
  title: {
    margin: 24,
  },
  digitsInputSection: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
  },
  digitsInputTitle: {
    marginBottom: 8,
  },
  digitsInputContainer: {
    width: rowWidth,
    display: 'flex',
    justifyContent: 'center',
    '& input[type="text"]': {
      flex: '0 0 auto !important',
      width: '20px !important',
    },
    marginBottom: 24,
  },
  invalidGesture: {
    animation: 'shake 0.4s 2',
  },
  resendSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 4,
    height: 48,
    marginTop: '16px',
  },
  '@keyframes': {
    shake: {
      '20%': {
        transform: 'translateX(-4px)',
      },

      '40%': {
        transform: 'translateX(0)',
      },

      '60%': {
        transform: 'translateX(4px)',
      },

      '80%': {
        transform: 'translateX(0)',
      },
    },
  },
}));

export type MFAMethod = 'Email' | 'SMS' | 'Totp' | 'Unknown';

interface MfaResolverProps {
  method: MFAMethod;
  magicLinkEnabled: boolean;
  onSubmit: (code: string) => Promise<boolean>;
  isValid: boolean;
  onCodeRequest?: () => Promise<void>;
  retryTimer?: string;
}

const MfaResolver: FC<MfaResolverProps> = ({
  method,
  onCodeRequest,
  onSubmit,
  isValid,
  retryTimer,
  magicLinkEnabled,
}) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  const [invalid, setInvalid] = useState(false);
  const [onReady, setOnReady] = useState(false);

  const handleOnCodeRequest = () => {
    setOnReady(false);
    onCodeRequest().finally(() => {
      setOnReady(true);
    });
  };

  const triggerInvalidGesture = () => {
    setInvalid(true);
    const timeout = setTimeout(() => {
      setInvalid(false);
      clearTimeout(timeout);
    }, 250);
  };

  useEffect(() => {
    // on page load a request
    if (!onCodeRequest) {
      setOnReady(true);
      return;
    }

    onCodeRequest().finally(() => {
      setOnReady(true);
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      return;
    }
    triggerInvalidGesture();
  }, [isValid]);

  const renderContent = () => {
    if (method === 'Unknown') return <LoadingIndicator color="blue" />;

    if (magicLinkEnabled)
      return (
        <>
          <StandaloneLoadingIndicator color="blue" />
          <Text style={{ marginTop: 16 }} variant="body">
            {`A link was sent to you through ${method}`}
          </Text>
        </>
      );

    return (
      <div className={styles.digitsInputSection}>
        <div className={styles.digitsInputTitle}>
          <Text variant="body" component="div">
            Enter code
          </Text>
        </div>

        <div
          className={`${styles.digitsInputContainer} ${useClass(
            invalid,
            styles.invalidGesture,
          )}`}
        >
          <DigitsInput
            digitFontSize="1rem"
            key="digits-input"
            numberOfKeys={6}
            onCompletion={(code) => {
              return onSubmit(code);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {renderContent()}
      {onCodeRequest && method !== 'Unknown' && (
        <div className={styles.resendSection}>
          {retryTimer && <Text variant="body">{retryTimer}</Text>}
          <Button
            style={{ width: 160, height: 26 }}
            variant="primary"
            onClick={handleOnCodeRequest}
            disabled={!onReady || Boolean(retryTimer)}
          >
            Resend {method}
          </Button>
        </div>
      )}
    </div>
  );
};

export default MfaResolver;
