export default {
  background: {
    color: {
      value: '#f2f7fd',
    },
    position: '50% 50%',
    repeat: 'no-repeat',
    size: 'cover',
  },
  fullScreen: {
    enable: true,
    zIndex: 1,
  },
  interactivity: {
    events: {
      onClick: {
        mode: 'push',
      },
    },
  },
  particles: {
    shape: {
      type: 'image',
      images: [
        {
          src: '/public/cloud1.svg',
          width: 114,
          height: 64,
        },
        {
          src: '/public/cloud2.svg',
          width: 177,
          height: 94,
        },
        {
          src: '/public/cloud3.svg',
          width: 206,
          height: 108,
        },
      ],
    },
    move: {
      enable: true,
      outModes: {
        right: 'out',
      },
      speed: 0.08,
    },
    number: {
      density: {
        enable: true,
      },
      value: 20,
    },
    size: {
      random: {
        enable: true,
        minimumValue: 100,
      },
      value: {
        min: 30,
        max: 60,
      },
      animation: {
        minimumValue: 40,
      },
    },
    opacity: {
      random: {
        enable: true,
        minimumValue: 0.3,
      },
      value: {
        min: 0.1,
        max: 1,
      },
      animation: {
        speed: 1,
        minimumValue: 0.1,
      },
    },
  },
};
