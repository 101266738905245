import { AuthAPI, MfaMethod } from '@frontend-monorepo/cyolo-auth';
import { CompanyInfoStore } from '@frontend-monorepo/cyolo-company';
import { CurrentUserStore } from '@frontend-monorepo/cyolo-identity';
import { Store } from '@frontend-monorepo/cyolo-store';

import AppUrls from '../../model/entity/app-urls';
import AuthData from '../../model/entity/auth-data';
import EnrollmentRequirement from '../../model/entity/enrollment-requirement';
import GeneralAPI from '../../services/api/api';
import LocalAuthAPI from '../../services/api/auth';
import { ILegalDoc } from '../../services/response';
import ApprovalStore from '../approval-store';
import SupervisorApprovalStore from '../supervisor-approval-store';

import AuthProvidersStore from './auth-provider-store';
import UserIdentityStore from './user-identity-store';

type Stores = keyof DataStoreContainer;

/**
 * allows fetching from backend
 */
export interface Fetchable {
  fetch(): Promise<void>;
}

class DataStoreContainer {
  constructor(
    public userStore: CurrentUserStore = new CurrentUserStore(),
    public authDataStore = new Store<AuthData>(
      'auth_data',
      LocalAuthAPI.getAuthData,
      new AuthData(),
    ),
    public mfaDataStore = new Store<boolean>(
      'mfa_store',
      GeneralAPI.didUserPassMfa,
      false,
    ),
    public organizationMfaMethodsStore = new Store<MfaMethod[]>(
      'organization_mfa_methods',
      async () => {
        return (await AuthAPI.getOrganizationMfaMethods()).map(
          MfaMethod.fromRaw,
        );
      },
      [],
    ),
    public authProvidersStore = new AuthProvidersStore(),
    public userMfaMethodsStore = new Store<MfaMethod[]>(
      'user_mfa_methods',
      LocalAuthAPI.getUserMfaMethods,
      [],
    ),
    public enrollmentRequirementsStore = new Store<EnrollmentRequirement[]>(
      'enrollment_requirements',
      LocalAuthAPI.getEnrollmentRequirements,
      [],
    ),
    public urlsStore = new Store<AppUrls>(
      'app_urls',
      GeneralAPI.getAppUrls,
      new AppUrls(''),
    ),
    public companyInfoStore = new CompanyInfoStore(),
    public approvalStore = new ApprovalStore(),
    public supervisorApprovalStore = new SupervisorApprovalStore(),
    public userIdentityStore = new UserIdentityStore(),
    public legalDocStore = new Store<ILegalDoc>(
      'legal_doc',
      GeneralAPI.getLegalDoc,
      null,
    ),
  ) {}

  async fetch(...stores: Stores[]): Promise<void> {
    await Promise.all(stores.map((store) => (this[store] as Fetchable).fetch));
  }
}

export default DataStoreContainer;
