import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { autorun, when } from 'mobx';
import { inject, observer } from 'mobx-react';

import { LoadingIndicator } from '@frontend-monorepo/cyolo-ui';

import { StoreProps } from '../../../interface';
import { AppRoute } from '../../../routes';
import pageRedirect from '../../../services/page-redirect';

/**
 *
 * RedirectScreen is in charge of redirecting the user to the proper screen
 * based on his data
 */
const RedirectScreen: FC<StoreProps> = ({ rootStore }) => {
  const history = useHistory();
  const { redirectScreenState: state } = rootStore.stateStore;

  // update the store with idp from url hash param if it set - used on idp initiate flow.
  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const requestedIdp = hashParams.get('idp') || '';
    state.setSelectedIdpFromUrl(requestedIdp);
  }, []);

  // bootstrap state when loading is finished
  useEffect(() => {
    return when(
      () => state.isInitialLoadingFinished,
      () => {
        state.bootstrap();
      },
    );
  }, []);

  // redirect user to next screen
  useEffect(() => {
    return autorun(() => {
      if (state.redirectDestination === 'none') return;
      switch (state.redirectDestination) {
        case 'login':
          history.push(AppRoute.Login);
          return;
        case 'welcome':
          history.push(AppRoute.Welcome);
          return;
        case 'redirect-url':
          pageRedirect.performPageRedirect(state.redirectUrl);
          return;
        case 'redirect':
          pageRedirect.performPageRedirect();
          return;
      }
    });
  }, []);

  return (
    <div>
      <LoadingIndicator message="" color="blue" />
    </div>
  );
};

export default inject('rootStore')(observer(RedirectScreen));
