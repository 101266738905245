import { FC, useContext, useEffect } from 'react';

import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import { ToastContext } from '@frontend-monorepo/cyolo-toast';

import RootStore from '../../../stores/root-store';

interface ToastAdapterProps {
  rootStore?: RootStore;
}

// TODO: write comments

const ToastAdapter: FC<ToastAdapterProps> = ({ rootStore }) => {
  // TODO cyolotoast useToast hook that wraps useContext
  const { showToast } = useContext(ToastContext);
  useEffect(() => {
    const disposable = reaction(
      () => rootStore.uiStore.activeToast,
      (toast) => {
        if (!toast) return;
        showToast(toast.message, toast.type);
      },
    );
    return (): void => {
      disposable();
    };
  }, []);

  return null;
};

export default inject('rootStore')(observer(ToastAdapter));
