import React, { FC, useState } from 'react';
import httpStatus from 'http-status';

import { inject, observer } from 'mobx-react';

import { MfaId } from '@frontend-monorepo/cyolo-auth';
import {
  Card,
  RetryTextAndButton,
  StandaloneLoadingIndicator,
  Tab,
  Tabs,
} from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { StoreProps } from '../../../../interface';

import MfaResolver, { MFAMethod } from './MfaResolver/MfaResolver';

import './ContentCard.css';

const LoginOptions: FC<StoreProps> = ({
  rootStore: {
    uiStore,
    dataStores: { authDataStore: authStore },
    stateStore: { mfaScreenState },
  },
}) => {
  if (authStore.state === 'error') {
    return <RetryTextAndButton action={authStore.fetch} />;
  }

  const [isCodeValid, setIsCodeValid] = useState(true);
  const allowedMethods: MfaId[] = mfaScreenState.userMethods.map((i) => i.id);
  const useSMS: boolean = allowedMethods.includes(MfaId.SMS);
  const useQR: boolean = allowedMethods.includes(MfaId.TOTP);
  const useEmail: boolean = allowedMethods.includes(MfaId.EMAIL);
  const [isCodeSubmitInprogress, setIsCodeSubmitInprogress] =
    useState<boolean>(false); // prevent code paste to trigger 2 calls

  const resolveMethodUsingState = (method: MFAMethod): MFAMethod => {
    if (method === 'Totp') return method;

    // only relevant to SMS at this point
    if (!mfaScreenState.canExternalMFATypeBeInferred) return 'Unknown';

    return method;
  };

  const handleCodeSubmit = async (
    code: string,
    origin: string,
  ): Promise<boolean> => {
    if (isCodeSubmitInprogress) return true;
    try {
      setIsCodeSubmitInprogress(true);
      await mfaScreenState.handleDigitsInputCompletion(code, origin);
      uiStore.showToast('Code verified successfully');
      return true;
    } catch (err) {
      setIsCodeValid(false);
      console.log(err);
      if (err.message.includes(httpStatus.UNAUTHORIZED)) {
        mfaScreenState.resetVerificationState();
        uiStore.showToast(
          tryParsingBackendError(err, 'Incorrect code'),
          'refused',
        );
      } else {
        uiStore.showToast(
          tryParsingBackendError(err, 'Failed to verify code'),
          'refused',
        );
      }
      return false;
    } finally {
      setIsCodeSubmitInprogress(false);
      setIsCodeValid(true);
    }
  };

  const handleSmsCodeSend = async () => {
    try {
      await mfaScreenState.sendMfaCodeBySMS();
    } catch (err) {
      uiStore.showToast(tryParsingBackendError(err, err), 'refused');
    }
  };

  const handleEmailCodeSend = async () => {
    try {
      await mfaScreenState.sendMfaCodeByEmail();
    } catch (err) {
      uiStore.showToast(tryParsingBackendError(err, err), 'refused');
    }
  };

  if (mfaScreenState.isSupervised) {
    return (
      <div className="MfaScreen-empty-card">
        <StandaloneLoadingIndicator color="blue" />
      </div>
    );
  }

  return (
    <Tabs
      onTabChange={mfaScreenState.selectTab}
      tabAsTitle={true}
      growTabs={true}
    >
      {useQR && (
        <Tab
          label="Use QR"
          key={MfaId.TOTP}
          id={MfaId.TOTP}
          selectedTab={mfaScreenState.selectedMfaMethod?.id === MfaId.TOTP}
        >
          <Card>
            <MfaResolver
              magicLinkEnabled={false}
              method={resolveMethodUsingState('Totp')}
              isValid={isCodeValid}
              onSubmit={(code) => {
                return handleCodeSubmit(code, 'totp');
              }}
            />
          </Card>
        </Tab>
      )}
      {useSMS && (
        <Tab
          label="Use Phone Number"
          key={MfaId.SMS}
          id={MfaId.SMS}
          selectedTab={mfaScreenState.selectedMfaMethod?.id === MfaId.SMS}
        >
          <Card>
            <div className="MfaScreen-ContentCardContent">
              <MfaResolver
                magicLinkEnabled={mfaScreenState.isMagicLinkEnabled}
                method={resolveMethodUsingState('SMS')}
                isValid={isCodeValid}
                onCodeRequest={handleSmsCodeSend}
                onSubmit={(code) => {
                  return handleCodeSubmit(code, 'sms');
                }}
                retryTimer={
                  mfaScreenState.smsRequestStore
                    .formattedtimeUntilSmsCanBeSentAgain
                }
              />
            </div>
          </Card>
        </Tab>
      )}
      {useEmail && (
        <Tab
          label="Use Email"
          key={MfaId.EMAIL}
          id={MfaId.EMAIL}
          selectedTab={mfaScreenState.selectedMfaMethod?.id === MfaId.EMAIL}
        >
          <Card>
            <div className="MfaScreen-ContentCardContent">
              <MfaResolver
                magicLinkEnabled={mfaScreenState.isMagicLinkEnabled}
                method={resolveMethodUsingState('Email')}
                isValid={isCodeValid}
                onCodeRequest={handleEmailCodeSend}
                onSubmit={(code) => {
                  return handleCodeSubmit(code, 'email');
                }}
                retryTimer={
                  mfaScreenState.emailRequestStore
                    ?.formattedtimeUntilSmsCanBeSentAgain
                }
              />
            </div>
          </Card>
        </Tab>
      )}
    </Tabs>
  );
};

export default inject('rootStore')(observer(LoginOptions));
